@import '~@styles/colors.scss';
@import '~@styles/global.scss';

.previewer {
  transition: all 0.1s ease-in-out;
  box-shadow: $shadow50high;
  background: $white;
  border-radius: 10px;
  box-sizing: border-box;
  width: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  &.active {
    width: calc(75% - 15px);
    padding: 15px;
    position: relative;
  }
}
