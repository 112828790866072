@import '~@styles/colors.scss';

.MainPanel {
  width: 100%;
  min-height: calc(100vh - 60px);
  background: $lvl2-grey;
  margin-top: 60px;
  overflow: hidden;
  position: relative;
  background-image: url('~@assets/images/pxyz-logo.png');
  background-size: 120px auto;
  background-repeat: no-repeat;
  background-position: center calc(100% - 10px);
  h1 {
    font-size: 20px;
    font-weight: 300;
  }
}
