@import '~@styles/colors.scss';

.Trimmer {
  height: 30px;
  width: 100%;
  background: $lightgrey;
  position: relative;
  border-radius: 0 0 10px 10px;
  border-top: 1px solid $lightgrey;
  border-bottom: 2px solid $lightgrey;
  border-left: 2px solid $lightgrey;
  border-right: 2px solid $lightgrey;
  box-sizing: border-box;
  cursor: not-allowed;
  transition: all 0.1s ease-in-out;
  &.disabled {
    .clipBar {
      &:hover {
        .delete {
          opacity: 0;
          visibility: hidden;
        }
      }
      .activeBar {
        background: $grey;
        border-color: $lvl2-grey;
        .clipLength {
          color: $lightgrey;
        }
      }
      .startHandle,
      .endHandle {
        background: $lvl2-grey;
        color: $lightgrey;
      }
      .startHandle,
      .endHandle,
      .activeBar,
      .delete {
        pointer-events: none;
        cursor: not-allowed;
      }
    }
    .fillers {
      pointer-events: none;
      cursor: not-allowed;
    }
  }
  .fillers {
    opacity: 1;
    transition: opacity 0.1s ease-in-out;
  }
  .clipBar {
    position: absolute;
    height: 100%;
    border-radius: 10px;
    // overflow: hidden;
    &:hover {
      .delete {
        opacity: 1;
        visibility: visible;
      }
    }
    .delete {
      position: absolute;
      bottom: calc(100% - 15px);
      right: 0;
      height: 20px;
      width: 20px;
      padding: 5px 0;
      background: $darkpink;
      opacity: 0;
      visibility: hidden;
      cursor: pointer;
      border-radius: 10px;
      font-size: 0.8em;
      color: $white;
      line-height: 100%;
      transition: opacity 0.1s ease-in-out;
      text-align: center;
    }
    .activeBar {
      background: $pink;
      height: 100%;
      border-radius: 10px;
      border-top: 2px solid $darkpink;
      border-bottom: 2px solid $darkpink;
      box-sizing: border-box;
      cursor: not-allowed;
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      &:active {
        background: $pinkgradient;
      }
      .clipLength {
        width: 100%;
        line-height: 100%;
        text-align: center;
        color: $white;
        font-size: 0.8em;
        pointer-events: none;
      }
    }
    .startHandle,
    .endHandle {
      height: 100%;
      position: absolute;
      background: $darkpink;
      color: $white;
      opacity: 1;
      top: 0;
      cursor: grab;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 15px;
      max-width: 50%;
      overflow: hidden;
      z-index: 9999;
      pointer-events: painted;
      &:active {
        cursor: grabbing;
        background: $white;
        color: $darkpink;
      }
      svg {
        width: auto;
        height: 40%;
        pointer-events: none;
      }
    }

    .startHandle {
      border-radius: 10px 0 0 10px;
    }
    .endHandle {
      right: 0;
      border-radius: 0 10px 10px 0;
    }
  }
  &:active {
    background-color: $grey;
    border-color: $grey;
    .fillers {
      opacity: 0;
    }
    .clipLength {
      opacity: 1;
    }
    .clipBar {
      .delete {
        opacity: 0;
      }
    }
  }
}
