$black: #000;
$white: #f3f3f3;
$darkgrey: #252b2c;
$lvl2-grey: #2a3132;
$grey: #3e474a;
$lightgrey: #8b9192;
$pink: #fe0060;
$darkpink: #b62456;
$orange: #ff8a3f;
$vsgreen: #0b9f90;
$confirmgreen: #79b473;

$pink10: rgba(254, 0, 96, 0.1);

$black5: rgba(0, 0, 0, 0.05);
$black10: rgba(0, 0, 0, 0.1);
$black25: rgba(0, 0, 0, 0.25);
$black50: rgba(0, 0, 0, 0.5);

$white25: rgba(243, 243, 243, 0.25);
$white50: rgba(243, 243, 243, 0.5);

$gradientBlack: linear-gradient(
  0deg,
  rgba(37, 43, 44, 0) 0%,
  rgba(37, 43, 44, 1) 100%
);

$pinkgradient: linear-gradient(135deg, #fe0060 0%, 75%, #ff8a3f 100%);
