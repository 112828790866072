@import '~@styles/colors.scss';

.saveBuild {
  width: 100%;
  max-width: 600px;
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  box-sizing: border-box;
  border-left: 1px solid $grey;
  margin: 15px 0;
  &:first-child {
    padding: 0 30px 0 15px;
    border-left: none;
  }
  .header {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    max-height: 30vh;
    img {
      height: 100%;
      max-height: 20vh;
      max-width: 100%;
    }
  }
  .body {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      font-size: 1.5em;
      margin: 0 0 15px 0;
    }
    p {
      margin: 0 0 15px 0;
      // max-width: 250px;
    }
    form {
      width: 100%;
      display: flex;
      .urlBox {
        overflow: hidden;
        text-overflow: ellipsis;
        background: $lightgrey;
        padding: 5px;
        color: $white;
        cursor: copy;
        border: none;
        height: 30px;
        box-sizing: border-box;
        flex-grow: 1;
        &:focus {
          background-color: $grey;
          border: none;
          outline: none;
        }
      }
      .button {
        min-width: 90px;
        display: inline-block;
        flex-shrink: 0;
      }
    }
    .vastButtons {
      display: flex;
      flex-direction: column;
      width: 100%;
      .creativeLinks {
        display: grid;
        grid-template-columns: 50% 1fr;
        grid-gap: 10px 10px;
        margin-top: 15px;
        a {
          > div {
            width: 100%;
            margin-left: 0;
            margin-top: 0;
          }
        }
      }
    }
    .response {
      margin-top: 10px;
      font-style: italic;
    }
  }
}
