@import '~@styles/colors.scss';

.videoPlayer {
  display: block;
  border: 1px solid $lightgrey;
  overflow: hidden;
  max-height: 100vh;
  video {
    width: 100%;
    height: auto;
    max-height: 100vh;
    pointer-events: none;
  }
  *::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
  }
  *::-webkit-media-controls-volume-slider-container {
    display: none !important;
    -webkit-appearance: none;
  }
  *::-webkit-media-controls-volume-slider {
    display: none !important;
    -webkit-appearance: none;
  }
  *::-webkit-media-controls-mute-button {
    display: none !important;
    -webkit-appearance: none;
  }
  *::-webkit-media-controls-fullscreen-button {
    display: none !important;
    -webkit-appearance: none;
  }
  *::-webkit-media-controls-mute-button {
    display: none !important;
    -webkit-appearance: none;
  }
  *::-webkit-media-controls-mute-button {
    display: none !important;
    -webkit-appearance: none;
  }
}
