@import '~@styles/colors.scss';

.NewClip {
  position: absolute;
  background: transparent;
  height: 100%;
  border-radius: 10px;
  border: 1px dashed $white;
  box-sizing: border-box;
  opacity: 0.5;
  background-image: url('~@assets/images/add.svg');
  background-position: center center;
  background-size: 80% 80%;
  background-repeat: no-repeat;
  transition: opacity 0.1s ease-in-out;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  &.hidden {
    opacity: 0;
  }
}
