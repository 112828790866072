@import '~@styles/colors.scss';

.previewVideo {
  height: auto;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  h1 {
    text-align: center;
    font-size: 1.2em;
    line-height: 30px;
    color: $grey;
    text-transform: capitalize;
    font-weight: normal;
    margin: 0 0 10px 0;
    overflow: hidden;
    // background: $grey;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    font-weight: normal;
    svg {
      margin-right: 10px;
    }
  }
  canvas {
    width: 100%;
    height: auto;
    border: 1px solid $grey;
    box-sizing: border-box;
    background-color: $darkgrey;
    background-image: url('~@assets/images/loading.gif');
    background-size: 60px auto;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .previewController {
    width: 100%;
    background: $grey;
    border-right: 1px solid $grey;
    border-bottom: 1px solid $grey;
    border-left: 1px solid $grey;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-sizing: border-box;
    overflow: hidden;
  }
}
