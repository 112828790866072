@import '~@styles/colors.scss';
@import '~@styles/global.scss';

.SegmentPanel {
  // width: calc(25% - 15px); // 4 columns
  width: calc(33.33% - 15px); // 3 columns
  min-width: 60px;
  margin-right: 15px;
  background: $grey;
  padding: 15px;
  box-shadow: $shadow25low;
  border-radius: 10px;
  position: relative;
  transition: transform 0.1s ease-in-out, width 0.1s ease-in-out;
  box-sizing: border-box;
  &.disabled {
    background: $darkgrey;
  }
  &.expand {
    width: calc(90% - 5px);
    z-index: 9999;
    box-shadow: $shadow25high;
  }
  &.shrink {
    width: calc(10% - 5px);
    margin-right: -30px;
    pointer-events: none;
    &.small {
      &:nth-child(2) {
        z-index: 999;
        transform: scale(0.9);
        margin-left: -30px;
        filter: blur(1px);
        transform-origin: top left;
      }
      &:nth-child(3) {
        z-index: 99;
        transform: scale(0.8);
        filter: blur(2px);
        transform-origin: top left;
      }
      &:nth-child(4) {
        z-index: 9;
        transform: scale(0.7);
        filter: blur(3px);
        transform-origin: top left;
      }
    }
    &.medium {
      &:nth-child(1) {
        z-index: 999;
        transform: scale(0.9);
        filter: blur(1px);
        transform-origin: top right;
      }
      &:nth-child(3) {
        z-index: 99;
        transform: scale(0.9);
        filter: blur(1px);
        margin-left: -30px;
        transform-origin: top left;
      }
      &:nth-child(4) {
        z-index: 9;
        transform: scale(0.8);
        filter: blur(2px);
        transform-origin: top left;
      }
    }
    &.large {
      &:nth-child(1) {
        z-index: 9;
        transform: scale(0.8);
        filter: blur(2px);
        transform-origin: top right;
      }
      &:nth-child(2) {
        z-index: 99;
        transform: scale(0.9);
        filter: blur(1px);
        transform-origin: top right;
      }
      &:nth-child(4) {
        z-index: 9;
        transform: scale(0.9);
        margin-left: -30px;
        filter: blur(1px);
        transform-origin: top left;
      }
    }
    &.outro {
      &:nth-child(1) {
        z-index: 9;
        transform: scale(0.7);
        filter: blur(3px);
        transform-origin: top right;
      }
      &:nth-child(2) {
        z-index: 99;
        transform: scale(0.8);
        filter: blur(2px);
        transform-origin: top right;
      }
      &:nth-child(3) {
        z-index: 999;
        transform: scale(0.9);
        filter: blur(1px);
        transform-origin: top right;
      }
    }
    .segmentLength,
    .segmentWarning {
      span:not(.totalLength),
      .recommended {
        display: none !important;
      }
    }
    .previewWrapper,
    .toggle {
      display: none !important;
    }
    .videoCardWrap {
      background-color: $lightgrey;
      border-radius: 10px;
      height: 200px;
    }
  }
  &.previewHide {
    width: 0;
    overflow: hidden;
    min-width: 0;
    padding: 15px 0;
    margin: 0;
    div {
      opacity: 0;
    }
  }
  .dropPreview {
    border: 1px dotted $lightgrey;
    margin-top: 15px;
    padding: 15px;
    box-sizing: border-box;
    border-radius: 10px;
  }
  .dragGhost {
    opacity: 0.7;
    transform: rotate(5deg);
    transition: all 0.1s ease-in-out;
  }
  .dropGhost {
    transform: rotate(-5deg) scale(0.8);
    transition: transform 0.1s ease-in-out;
  }
}
