@import '~@styles/colors.scss';
@import '~@styles/global.scss';

.Button {
  width: auto;
  padding: 0 15px;
  text-align: center;
  border-radius: 5px;
  height: 30px;
  background: $vsgreen;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  color: $white;
  box-sizing: border-box;
  transition: all 0.1s ease-in-out;
  margin-left: 10px;
  position: relative;
  &.disabled {
    background: $grey;
    color: $lightgrey;
    cursor: not-allowed;
  }
  &:hover {
    &:not(.disabled) {
      box-shadow: 0px 0px 5px $darkgrey;
    }
  }
  &:focus {
    outline: 0;
    outline-offset: 0;
  }

  &:active {
    transform: translate(0px, 0px);
  }
  &.tooltip {
    cursor: help;
    position: relative;
    pointer-events: visible;
    .tooltiptext {
      visibility: hidden;
      width: 100%;
      min-width: 100px;
      background-color: $white;
      color: $darkgrey;
      box-shadow: $shadow25low;
      text-align: left;
      padding: 10px;
      box-sizing: border-box;
      border-radius: 10px;
      position: absolute;
      z-index: 1;
      right: 0;
      top: 100%;
      opacity: 0;
      transition: opacity 0.1s ease-in-out, transform 0.3s ease-in-out;
    }
    &:hover .tooltiptext {
      visibility: visible;
      opacity: 1;
      transform: translateY(10px);
    }
  }
  .spin {
    animation: icon-spin 2s infinite linear;
  }
  &.pulse {
    box-shadow: 0 0 0 0 rgba(255, 138, 63, 1);
    transform: scale(1);
    animation: icon-pulse 1.5s infinite;
  }
  @keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  @keyframes icon-pulse {
    0% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(255, 138, 63, 0.7);
    }

    50% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 138, 63, 0);
    }

    100% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(255, 138, 63, 0);
    }
  }
  svg {
    height: 1em;
    width: 1em;
    display: inline-block;
  }
  .notification {
    @keyframes rotate {
      100% {
        transform: rotate(1turn);
      }
    }
    @keyframes glow {
      0% {
        background-color: $darkgrey;
      }
      50% {
        background-color: $darkpink;
      }
      100% {
        background-color: $darkgrey;
      }
    }
    position: absolute;
    z-index: 9999;
    height: 22px;
    width: 22px;
    left: -10px;
    top: -10px;
    font-size: 0.8em;
    font-weight: bolder;
    border-radius: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $darkpink;
    box-shadow: 0px 0px 5px $darkgrey;
    overflow: hidden;
    &::before {
      content: '';
      position: absolute;
      z-index: -2;
      left: -50%;
      top: -50%;
      width: 200%;
      height: 200%;
      background-color: $vsgreen;
      background-repeat: no-repeat;
      background-size: 50% 50%, 50% 50%;
      background-position: 0 0, 100% 0, 100% 100%, 0 100%;
      background-image: linear-gradient($vsgreen, $vsgreen),
        linear-gradient($darkpink, $darkpink), linear-gradient($orange, $orange),
        linear-gradient($pink, $pink);
      animation: rotate 2s linear infinite;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      left: 3px;
      top: 3px;
      width: calc(100% - 6px);
      height: calc(100% - 6px);
      background-color: $darkgrey;
      border-radius: 510px;
      animation: glow 2s linear infinite;
    }
  }
}
