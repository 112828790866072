@import '~@styles/colors.scss';
@import '~@styles/global.scss';

.header {
  position: relative;
  margin-bottom: 0.5em;
  &.shrink {
    .toggle,
    .segmentLength,
    .segmentWarning,
    .previewWrapper {
      display: none;
    }
  }
  h1 {
    text-align: left;
    font-size: 1.2em;
    line-height: 30px;
    color: $lightgrey;
    text-transform: capitalize;
    font-weight: normal;
    margin: 0 0 0.5em 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .toggle {
    display: inline-block;
    margin-left: 10px;
    text-transform: none;
    &.tooltip {
      cursor: help;
      .tooltiptext {
        visibility: hidden;
        width: 100%;
        background-color: $white;
        color: $darkgrey;
        box-shadow: $shadow25low;
        text-align: left;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 10px;
        position: absolute;
        z-index: 1;
        right: 0;
        opacity: 0;
        transition: opacity 0.1s ease-in-out, transform 0.3s 0.1s ease-in-out;
        font-size: 0.8em;
        line-height: normal;
      }
      &:hover .tooltiptext {
        visibility: visible;
        opacity: 1;
        transform: translateY(10px);
      }
    }
  }
  .segmentLength,
  .segmentWarning,
  .placeholder {
    bottom: -0.9em;
    color: $white;
    text-align: left;
    font-size: 0.9em;
    min-height: 1em;
    line-height: 1em;
    transition: all 0.1s 0.1s ease-in-out;
    .recommended {
      display: inline;
      margin-left: 5px;
      span {
        font-weight: bold;
      }
    }
    &.fail {
      svg,
      .totalLength {
        font-weight: bold;
        color: $pink;
      }
    }
    &.pass {
      svg,
      .totalLength {
        font-weight: bold;
        color: $confirmgreen;
      }
    }
    .totalLength {
      transition: color 0.1s 0.1s ease-in-out;
    }
    svg {
      transition: color 0.1s 0.1s ease-in-out;
    }
  }
  .segmentWarning {
    color: $pink;
  }
  .previewWrapper {
    position: absolute;
    top: 0px;
    right: 0px;
    transition: all 0.1s 0.1s ease-in-out;
  }
}
