@import '~@styles/colors.scss';
@import '~@styles/global.scss';

.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  background-color: $black50;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  position: fixed;
  z-index: 999999;
  background-color: $white;
  width: 70%;
  top: 20%;
  left: 15%;
  box-shadow: $shadow50low;
  padding: 16px;
  box-sizing: border-box;
  transform-origin: center right;
  transition: all 0.3s 0.1s ease-out;
  border-radius: 10px;
  .close {
    position: absolute;
    top: -10px;
    right: -10px;
    background: $pink;
    color: $white;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    box-shadow: $shadow50med;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    &:active {
      box-shadow: $shadow50low;
      background: $darkpink;
    }
  }
}
