@import '~@styles/colors.scss';

.assetUploader {
  padding: 15px;
  h1 {
    font-size: 1.2em;
    margin: 0 0 15px 0;
  }
  form {
    outline: 1px dashed $lightgrey;
    outline-offset: -15px;
    border-radius: 10px;
    transition: all 0.15s ease-in-out;
    font-size: 1em;
    background-color: $black5;
    position: relative;
    padding: 100px 20px;
    &:hover {
      background-color: $black10;
    }
    &.dragover {
      outline-color: $white;
      outline-offset: -30px;
      background-color: $vsgreen;
      label {
        color: $white !important;
        svg {
          height: 100px;
          color: $white !important;
        }
      }
    }
    .uploader {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }
      label {
        max-width: 80%;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        display: inline-block;
        overflow: hidden;
        transition: all 0.15s linear;
        .dragndrop {
          display: inline;
        }
        svg {
          width: 100%;
          height: 80px;
          color: $grey;
          display: block;
          margin-bottom: 40px;
          transition: all 0.15s linear;
        }
      }
      .button {
        font-weight: 700;
        color: #e5edf1;
        background-color: #39bfd3;
        display: none;
        padding: 8px 16px;
        margin: 40px auto 0;
        border: none;
      }
    }
    .uploading,
    .success,
    .error {
      display: none;
    }
  }
  .assetDrawer {
    .uploadQueue {
      background: $grey;
    }
    .uploadError {
      background-color: $darkpink;
    }
    .uploadQueue,
    .uploadError {
      border: none;
      height: 50px;
      width: 100%;
      border-radius: 10px;
      margin-top: 15px;
      position: relative;
      overflow: hidden;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      .fileName {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: absolute;
        z-index: 99;
        top: 0;
        left: 15px;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $white;
        font-weight: bolder;
        span {
          font-weight: lighter;
          margin-left: 10px;
        }
      }
      .progressBar {
        position: absolute;
        z-index: 9;
        top: 0;
        left: 0;
        bottom: 0;
        background: $vsgreen;
        height: 100%;
        transition: width 0.1s linear;
      }
      .pauseUpload,
      .cancelUpload,
      .resumeUpload {
        font-size: 1em;
        z-index: 999;
        height: 24px;
        width: 24px;
        position: absolute;
        top: 13px;
        color: $white;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.15s ease-in-out;
        transform-origin: center center;
        cursor: pointer;
      }
      .pauseUpload,
      .resumeUpload {
        right: 50px;
      }
      .pauseUpload {
        background-color: $orange;
      }
      .resumeUpload {
        background-color: $confirmgreen;
      }
      .cancelUpload {
        background-color: $pink;
        right: 13px;
      }
    }
  }

  &.segmentUploader {
    color: $white50;
    padding: 0;
    margin-top: 15px;
    form {
      padding: 99px 30px;
      .uploader {
        label {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          svg {
            color: $white50;
            width: 50%;
          }
        }
      }
    }
    .uploadQueue,
    .uploadError {
      background: $black5;
      .fileName {
        width: 70%;
        font-size: 12px;
        font-weight: normal;
        text-align: left;
        span {
          font-weight: bolder;
        }
      }
    }
  }
}
