.EditView {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 999;
  .SegmentPanels {
    width: calc(100% - 45px);
    margin: 30px 15px 30px 30px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    // overflow: hidden;
  }
}
