@import '~@styles/colors.scss';
@import '~@styles/global.scss';

.Navigation {
  background: $vsgreen;
  font-size: 1em;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 60px;
  z-index: 99999;
  padding: 15px 30px;
  box-sizing: border-box;
  box-shadow: $shadow50low;
  .vsbLogo {
    height: 35px;
    margin: -5px 0;
    pointer-events: none;
  }
  .pxyzLogo {
    height: 30px;
    margin-left: 15px;
    padding-left: 15px;
    border-left: 1px solid $lightgrey;
    pointer-events: none;
  }
  .ButtonWrapper {
    display: flex;
    flex-direction: row;
  }
}
