@import '~@styles/colors.scss';
@import '~@styles/global.scss';

.controller {
  height: auto;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: $white;
  div {
    margin: 0 15px;
    font-size: 1.2em;
    border: 2px solid $white;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    background-color: $white25;
    &:hover {
      transform: scale(1.1);
      transition: all 0.1s ease-in-out;
      background-color: $vsgreen;
    }
    &:active {
      transform: scale(1);
      box-shadow: none;
      transition: none;
    }
  }
}
