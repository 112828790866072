.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.App-link {
  color: #61dafb;
}

// global CSS
.smooth-dnd-container {
  min-height: 350px !important;
  height: 100%;
  transition: opacity 0.1s 0.1s ease-in-out;
  &.vertical {
    & > .smooth-dnd-draggable-wrapper {
      overflow: visible !important;
    }
  }
}

a {
  text-decoration: none;
}

/* css */
html {
  scroll-behavior: smooth;
}

.fadeOut {
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
}
