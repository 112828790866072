@import '~@styles/colors.scss';
@import '~@styles/global.scss';

.VideoCard {
  display: block;
  width: 100%;
  background: $white;
  margin-top: 15px;
  border-radius: 5px;
  box-shadow: $shadow25low;
  padding: 15px;
  position: relative;
  transition: opacity 0.1s ease-in-out, position 0.1s 0.1s linear;
  height: 100%;
  box-sizing: border-box;

  &.placeholder {
    background: $lightgrey;
    height: 0px;
    min-height: 0px;
    margin: 0;
    transition: min-height 0.1s linear, margin 0.1s 0.1s linear;
    padding: 0;
    div {
      display: none;
    }
  }
  &.shrink {
    background: transparent;
    box-shadow: none;
    opacity: 0.5;
    .videoPlayer {
      max-height: 0px;
      overflow: hidden;
    }
    div:not(.expand) {
      display: none;
    }
    div.expand {
      top: 5px;
      right: 5px;
      color: $white;
    }
    div.more {
      display: flex;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
      color: $white;
    }
  }
  &.notPlaying {
    pointer-events: none;
    > div:not(.trimmerWrap) {
      opacity: 0.5;
    }
  }
  .videoPlayer {
    transition: all 0.1s ease-in-out;
  }
  .trimmerWrap {
    position: relative;
    .progressBar {
      position: absolute;
      width: 2px;
      height: calc(100% + 10px);
      position: absolute;
      left: 0;
      top: -5px;
      background: $black;
      z-index: 9999;
      transition: transform 0.1s linear;
    }
  }
  .title {
    text-align: left;
    margin-bottom: 5px;
    font-size: 1em;
    color: $darkgrey;
    width: calc(100% - 35px);
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .timestamp {
    text-align: left;
    color: $grey;
    font-size: 0.9em;
    margin-bottom: 15px;
    text-overflow: ellipsis;
    width: calc(100% - 30px);
    overflow: hidden;
    height: 1em;
  }

  .bottomBar {
    display: flex;
    margin-top: 15px;
    font-size: 1em;
    justify-content: space-between;
    line-height: 30px;
    .clipLength {
      text-align: left;
      color: $lightgrey;
      font-size: 0.9em;
      svg {
        margin-right: 5px;
      }
    }
    .actions {
      color: $white;
      display: flex;
      &.disabled {
        div {
          background: $lightgrey;
          pointer-events: none;
        }
      }
      div {
        margin-left: 5px;
        width: 30px;
        height: 30px;
        border-radius: 10px;
        cursor: pointer;
      }
      .copy {
        background: $vsgreen;
      }
      .delete {
        background: $darkpink;
      }
    }
  }
  .more {
    display: none;
  }
  .expand {
    position: absolute;
    top: 15px;
    right: 15px;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    &:hover {
      background: $vsgreen;
      color: $white;
    }
    &.invert {
      background: $darkpink;
      color: $white;
    }
  }
}
