@import '~@styles/colors.scss';
@import '~@styles/global.scss';

.errorPanel {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: auto;
  bottom: 0;
  left: 0;
  right: 0;
  background: $pinkgradient;
  color: $white;
  transition: transform 0.3s ease-in-out;
  z-index: 9999999;
  padding: 10px 50px;
  box-sizing: border-box;
  box-shadow: $shadow50high;
  .errorWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .close {
      position: absolute;
      right: -40px;
      cursor: pointer;
    }
  }
}
